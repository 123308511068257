import { observer } from 'mobx-react-lite';
import { useContext } from 'react';
import { Outlet } from 'react-router-dom';

import { StoreContext } from '~/modules/common';
import { WarningBanner } from '~/modules/common/components/WarningBanner';
import WelcomePopUp from '~/modules/common/components/WelcomePopUp';
import { NavBar, NavFooter, PendingActionBanner } from '~/modules/navigation';
import { SwapPending, SwapProgress } from '~/modules/swap';

import { SHOW_WELCOME_POP_UP } from './config';
import { WalletComplianceModal } from './modules/common/components/WalletComplianceModal';

function CatalystApp() {
  const { wallet } = useContext(StoreContext);
  const { account } = wallet;
  const isTrmBlacklisted = Boolean(account && account.trmBlacklisted);
  return isTrmBlacklisted ? (
    <WalletComplianceModal
      isOpen={true}
      onClose={() => {
        window.location.reload();
      }}
      onConnectAnotherWallet={() => {
        window.location.reload();
      }}
    />
  ) : (
    <div className="flex h-screen flex-grow flex-col overflow-auto pb-10 lg:pb-0">
      <WarningBanner />
      <PendingActionBanner />
      <NavBar />
      <SwapProgress />
      <SwapPending />
      <Outlet />
      <NavFooter />
      {SHOW_WELCOME_POP_UP && <WelcomePopUp />}
    </div>
  );
}

export default observer(CatalystApp);
